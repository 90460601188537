const backend_codes = require("../backend_codes")
const config = require('../config')

class WebSocketClient {
    constructor (onConnect, onDisconnect, OnMessage2) {
        
        this._handlers = new Set()
        this._onConnect = onConnect
        this._onDisconnect = onDisconnect
        this._onMessage2 = OnMessage2
        // this.chamadaExterna => this.chamadaExterna
        this.reconnect()
        
    }

    reconnect() {
        this._ws = new WebSocket(config.baseWsUrl)
        this._ws.onopen = this._onConnect
        this._ws.onclose = event => this._onClose(event)
        this._ws.onmessage = message => this._onMessage(message)
    }

    addHandlers(handlers) {
        for(const handler of handlers) {
            this._handlers.add(handler)
        }
    }

    close() {
       this._ws.onclose = null
       this._ws.close()
    }

    doLogin(token,pass) {
        //console.log('WebSocketClient doLogin');
        this._ws.send(JSON.stringify({action: backend_codes.LOGIN, token,pass}))
        if (!token || typeof token !== 'string')
            return false

        this._ws.send(JSON.stringify({action: backend_codes.LOGIN, token,pass}))
        return true
    }

    _onMessage(message) {
        try {
           
           // console.log('_onmessage')
         //   console.log(message)
            const msg = JSON.parse(message.data)
         //   console.log('_onmessage data')
        //    console.log(msg)
            if (this._onMessage2(msg)) {
                return true
            }
            for(const handler of this._handlers) {
                if (handler.handle(msg))
                    return
            }
            
            console.log(`Untreated message (mensagem não tratada): ${message.data.substr(0, 100)}`)
        }
        catch (e) {
            console.error(`Erro while parsing websocket message: ${e}. Message is ${message.data.substr(0, 100)}`)
        }
    }

    _onClose(event) {
        if (this._onDisconnect && !this._onDisconnect(event.code))
            return

        window.setTimeout(() => {
            this.reconnect()
        }, 10000)
    }
    
}

module.exports = WebSocketClient