/**
 * Retorna a diferença de tempo, em segundos, entre uma data/hora recebida e a data/hora local.
 * Basicamente é o número que diz o quanto de segundos deve-se subtrair da data/hora local para chegar
 * Na data/hora do servidor.
 * @param {Date} startTimestamp A data/hora de início do evento
 * @param {Number} elapsedSeconds Os segundos decorridos desde o início do evento
 * @returns Os segundos de diferença entre a data/hora recebida e a data/hora da máquina. Retorna 0 se
 *  startTimestamp não for um Date, ou elapsedSeconds for negativo.
 */
export function getTimeSyncFactor(startTimestamp, elapsedSeconds) {
    if (!(startTimestamp instanceof Date) || elapsedSeconds < 0)
        return 0

    // s - eS - t/1000, onde
    //  s: segundos locais, formato UNIX
    //  eS: segundos já passados desde t, segundo o servidor
    //  t: data/hora de início do evento, segundo o relógio servidor
    return Date.now() / 1000 - elapsedSeconds - startTimestamp.getTime() / 1000
}

/**
 * @param {Number} xSegundosApontamento  A horo atual
 * @param {Number} pHoursPerformed 
 * @returns 
 */

export function calculateHoursProduced(xSegundosApontamento, pHoursPerformed) {
    var SegundosTrabalhados = (pHoursPerformed * 3600);
    var total = SegundosTrabalhados + xSegundosApontamento;
    return (elapsedTempoExecucao(total));
}

export function calculateHoursSensor(DataRequisicao) {
    let GetdataAtual = new Date(); // Data atual

    return (elapsedSecondsToTimeStr((GetdataAtual - DataRequisicao) / 1000));
}

/**
 * Retorna os segundos corridos, já corrigidos para a data/hora do servidor
* @param {Date} xnow  A horo atual
* @param {Date} startTimestamp A data/hora de início do evento
 * @param {Number} timeDiff O fator de diferença entre a data/hora do servidor e a local
 * @returns A quantidade de segundos corridos e corrigidos de acordo com o horário do servidor
 */
export function getSyncedElapsedSeconds(xnow , startTimestamp, timeDiff) {
    var data = new Date()
    data = xnow
    return data/1000 - (startTimestamp.getTime()/1000 + timeDiff)
}

/**
 * 
 * @param {Number} elapsedSeconds Tempo decorrido em segundos
 * @returns Retorna uma string de tempo no format HH:MM:SS
 */
export function elapsedTempoExecucao(elapsedSeconds) {
    const hours=String(Math.floor(elapsedSeconds/3600)).padStart(2, '0')
    const minutes=String(Math.floor(elapsedSeconds%3600/60)).padStart(2, '0')
    const seconds=String(Math.floor(elapsedSeconds%60)).padStart(2, '0')

    return`${hours}:${minutes}:${seconds}`;
}

export function elapsedSecondsToTimeStr(elapsedSeconds) {
    const hours=String(Math.floor(elapsedSeconds/3600)).padStart(2, '0')
    const minutes=String(Math.floor(elapsedSeconds%3600/60)).padStart(2, '0')
    const seconds=String(Math.floor(elapsedSeconds%60)).padStart(2, '0')

    let resposta = {MaiorQue10: false, tempo: ""}

    if (parseInt(minutes) < 10 && parseInt(hours) == 0){
        resposta.MaiorQue10 = false;
    }else{
        resposta.MaiorQue10 = true;
    }
    resposta.tempo = `${hours}:${minutes}:${seconds}`;

    return resposta
}
