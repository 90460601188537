/* Todo retorno deve ser um JSON do tipo
{code: <CODE>, content: <Object>|null}, onde
    - <CODE> é o código da operação, listado abaixo; e
    - <Object> é um objeto de conteúdo da notificação.

Para o BACKEND_STATUS, content = {status: true|false}, onde status indica se o backend
está ativo (true) ou inativo (false)

Leia o README.PROTOCOL.md para saber mais sobre o protocólo de comunicação.
*/

module.exports = {
    // códigos de LOGIN
    LOGIN: 1,
    LOGOUT: 2,

    
    // Códigos de dashboard
    FULL_RELOAD: 10,
    DASHBOARD_REMOVED: 11,
    DASHBOARD_UPDATED: 12,

    // Códigos de recursos
    RESOURCE_CHANGED: 20,
    RESOURCE_DELETED: 21,
    RESOURCE_PICTURE_CHANGED: 22,
    RESOURCE_OS_UPDATED: 23,

    // Códigos de OS
    OS_CHANGED: 30,
    OS_REMOVED: 31,
    OS_STATUS_CHANGED: 32,
    OS_ADDED: 33
}